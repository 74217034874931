import { lazy } from 'react';

const RestaurantMaster = lazy(() => import(
  /* webpackChunkName:"RestaurantMasterComponent" */ '../module/restaurantManagment/pages/restaurantMaster/RestaurantMaster'
));
const RestaurantGroups = lazy(() => import(
  /* webpackChunkName:"RestaurantMasterComponent" */ '../module/restaurantManagment/pages/restaurantGroups/RestaurantGroups'
));
const OLODetails = lazy(() => import(
  /* webpackChunkName:"OLODetails" */ '../module/oloManagement/pages/oloDetails/OloDetailsMaster'
));
const RestaurantShutdownLookupMaster = lazy(() => import(
  /* webpackChunkName:"RestaurantShutdownLookupMaster" */ '../module/oloManagement/pages/lookupShutdown/RestaurantLookUpShutdownMaster'
));
const RestaurantAdditionalCharges = lazy(() => import(
  /* webpackChunkName:"RestaurantShutdownLookupMaster" */ '../module/restaurantManagment/pages/additionalCharges/AdditionalCharges'
));
const RestaurantServiceMapping = lazy(() => import(
  /* webpackChunkName:"RestaurantServiceMapping" */ '../module/restaurantManagment/pages/restaurantServiceMapping/RestaurantServiceMapping'
));
const RestaurantPaymentMapping = lazy(() => import(
  /* webpackChunkName:"RestaurantPaymentMapping" */ '../module/restaurantManagment/pages/restaurantPaymentMapping/RestaurantPaymentMapping'
));
const RestaurantPointPaymentMapping = lazy(() => import(
  /* webpackChunkName:"RestaurantPaymentMapping" */ '../module/restaurantManagment/pages/restaurantPointPaymentMapping/restaurantPointPaymentMapping'
));
const TechnicalShutdownMaster = lazy(() => import(
  /* webpackChunkName:"TechnicalShutdownMaster" */ '../module/oloManagement/pages/technicalShutdown/TechnicalShutdownMaster'
));
const RestaurantOperatingHours = lazy(() => import(
  /* webpackChunkName:"RestaurantOperatingHours" */ '../module/restaurantManagment/pages/restaurantOperatingHours/RestaurantOperatingHours'
));
// const RestaurantServiceMappingLookup = lazy(() => import(
// /* webpackChunkName:"RestaurantServiceMappingLookup" */
// ));
const PosMappingMaster = lazy(() => import(
  /* webpackChunkName:"RestaurantServiceMappingLookup" */ '../module/oloManagement/pages/posMapping/PosMappingMaster'
));
const RestaurantServiceMappingBatch = lazy(() => import(
  /* webpackChunkName:"RestaurantServiceMappingBatch" */ '../module/restaurantManagment/batch/restaurantServiceBatch/RestaurantServiceBatch'
));
const ScheduleShutdownBatch = lazy(() => import(
  /* webpackChunkName:"RestaurantServiceMappingBatch" */ '../module/oloManagement/batch/scheduleShutdownBatch/ScheduleShutdownBatch'
));
const RestaurantOperatingHoursBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantOperatingHoursBatch" */ '../module/restaurantManagment/batch/restaurantOperatingHoursBatch/RestaurantOperatingHoursBatch'
));
const RestaurantSchedulingHoursBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantSchedulingHoursBatch" */ '../module/restaurantManagment/batch/restaurantSchedulingHoursBatch/RestaurantSchedulingHoursBatch'
));
const RestaurantTradingHoursBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantOperatingHoursBatch" */ '../module/restaurantManagment/batch/restaurantTradingHoursBatch/RestaurantTradingHoursBatch'
));
const RestaurantDeliveryHoursBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantOperatingHoursBatch" */ '../module/restaurantManagment/batch/restaurantDeliveryHoursBatch/RestaurantDeliveryHoursBatch'
));
const RestaurantPaymentBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantPaymentBatch" */ '../module/restaurantManagment/batch/restaurantPaymentBatch/RestaurantPaymentBatch'
));
const RestaurantPublishBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantPaymentBatch" */ '../module/restaurantManagment/batch/restaurantPublishBatch/RestaurantPublishBatch'
));
const ProductExclusion = lazy(() => import(
  /* webpackChunkName:"BatchProductExclusion" */ '../module/oloManagement/pages/productExclusion/ProductExclusion'
));
const ProductExclusionBatch = lazy(() => import(
  /* webpackChunkName:"Batch" */ '../module/oloManagement/batch/productExclusionBatch/ProductExclusionBatch'
));
const ProductExclusionLookup = lazy(() => import(
  /* webpackChunkName:"BatchProductExclusionLookup" */ '../module/oloManagement/pages/productExclusionLookup/ProductExclusionLookup'
));
const HolidayLookupMaster = lazy(() => import(
  /* webpackChunkName:"BatchHolidayLookupMaster" */ '../module/oloManagement/pages/lookupHoliday/HolidayLookupMaster'
));
const Holiday = lazy(() => import(/* webpackChunkName:"Batch" */ '../module/oloManagement/pages/holiday/Holiday'));
const HolidayBatch = lazy(() => import(/* webpackChunkName:"Batch" */ '../module/oloManagement/batch/holidayBatch/HolidayBatch'));
const ProductMaster = lazy(() => import(
  /* webpackChunkName:"BatchProductMaster" */ '../module/productManagement/pages/productMaster/ProductMaster'
));
const ProductDetails = lazy(() => import(
  /* webpackChunkName:"BatchProductMaster" */ '../module/productManagement/pages/productDetails/ProductDetails'
));
const ProductRecommendation = lazy(() => import(
  /* webpackChunkName:"Batch" */ '../module/productManagement/pages/recommendedProduct/RecommendedProductMaster'
));
const UpsellCrossSellAddons = lazy(() => import(
  /* webpackChunkName:"BatchRecommendedOffers" */ '../module/productManagement/pages/upsellCrossSellAddons/UpsellCrossSellAddons'
));
const KioskConfigurationMaster = lazy(() => import(
  /* webpackChunkName:"BatchKioskConfigurationMaster" */ '../module/kiosk/pages/kioskConfigurations/KioskConfigurationMaster'
));
const PromotionMaster = lazy(() => import(
  /* webpackChunkName:"BatchPromotionMaster" */ '../module/coupon/pages/promotion/PromotionMaster'
));
const CouponMaster = lazy(() => import(
  /* webpackChunkName:"BatchPromotionMaster" */ '../module/coupon/pages/coupon/CouponMaster'
));
const AggregatorOfferMaster = lazy(() => import(
  /* webpackChunkName:"BatchPromotionMaster" */ '../module/coupon/pages/aggregatorOffers/AggregatorOfferMaster'
));
const CouponAssignment = lazy(() => import(
  /* webpackChunkName:"BatchPromotionMaster" */ '../module/coupon/pages/couponAssignment/CouponAssignment'
));
const MenuPull = lazy(() => import(
  /* webpackChunkName:"BatchPromotionMaster" */ '../module/productManagement/pages/menuPull/MenuPullMaster'
));
const MenuPullLookUp = lazy(() => import(
  /* webpackChunkName:"BatchPromotionMaster" */ '../module/productManagement/pages/menuPullLookUp/MenuPullLookUpMaster'
));
const TenantManagement = lazy(() => import(
  /* webpackChunkName:"TenantManagement" */ '../module/configurationSettings/pages/tenantManagement/TenantManagement'
));
const NetBanking = lazy(() => import(
  /* webpackChunkName:"LogOut" */ '../module/paymentConfiguration/pages/netbanking/NetBanking'
));
const OtherWallet = lazy(() => import(
  /* webpackChunkName:"LogOut" */ '../module/paymentConfiguration/pages/otherwallet/OtherWallet'
));
const BillDesk = lazy(() => import(
  /* webpackChunkName:"LogOut" */ '../module/paymentConfiguration/pages/billdesk/BillDesk'
));
const TenderOffer = lazy(() => import(
  /* webpackChunkName:"LogOut" */ '../module/paymentConfiguration/pages/tenderoffer/TenderOffer'
));
const ImageUploadMaster = lazy(() => import(
  /* webpackChunkName:"ImageUploadMaster" */ '../module/productManagement/pages/imageUpload/ImageUploadMaster'

));
const PublishRestaurant = lazy(() => import(
  /* webpackChunkName:"PublishRestaurant" */ '../module/restaurantManagment/pages/publishRestaurant/PublishRestaurant'
));
const ManageRole = lazy(() => import(
  /* webpackChunkName:"PublishRestaurant" */ '../module/userManagement/pages/manageRole/ManageRoleMaster'

));
const DashboardMaster = lazy(() => import(/* webpackChunkName:"Dashboard" */ '../module/report/page/dashboard/DashboardMaster'));
const OrderReport = lazy(() => import(
  /* webpackChunkName:"orderReport" */ '../module/report/page/orderReport/OrderReport'
));
const CouponRedemptionReport = lazy(() => import(
  /* webpackChunkName:"CouponRedemptionReport" */ '../module/report/page/couponRedemptionReport/CouponRedemptionReport'
));
const TimePeriodDetailReport = lazy(() => import(
  /* webpackChunkName:"orderReport" */ '../module/report/page/timePeriodDetailReport/TimePeriodDetailReport'

));
const GiftCardUsageReport = lazy(() => import(
  /* webpackChunkName:"GiftCardUsageReport" */ '../module/report/page/giftCardUsageReport/GiftCardUsageReport'

));
const GiftCardPurchaseReport = lazy(() => import(
  /* webpackChunkName:"GiftCardPurchaseReport" */ '../module/report/page/giftCardPurchaseReport/GiftCardPurchaseReport'

));
const CallCenterAgentReport = lazy(() => import(
  /* webpackChunkName:"CallCenterAgentReport" */ '../module/report/page/callCenterAgentReport/CallCenterAgentReport'

));
const OnlineStorePerformanceReport = lazy(() => import(
  /* webpackChunkName:"OnlineStorePerformanceReport" */ '../module/report/page/onlineStorePerformanceReport/OnlineStorePerformanceReport'
));
const PaymentStatusReport = lazy(() => import(
  /* webpackChunkName:"PaymentStatusReport" */ '../module/report/page/paymentStatusReport/PaymentStatusReport'
));
const ProductExclusionReport = lazy(() => import(
  /* webpackChunkName:"PaymentStatusReport" */ '../module/report/page/productExclusionReport/ProductExclusionReport'
));

const PageNotFound = lazy(() => import(
  /* webpackChunkName:"PageNotFound" */ '../module/pageNotFound/pages/PageNotFound'
));
const SignIn = lazy(() => import(
  /* webpackChunkName:"SignIn" */ '../module/auth/pages/signIn/SignIn'
));
const SignOut = lazy(() => import(
  /* webpackChunkName:"SignOut" */ '../module/auth/pages/signOut/SignOut'
));

const OrderReportFilterReport = lazy(() => import(
  /* webpackChunkName:"orderDetailsReport" */ '../module/report/page/orderDetailsReport/OrderDetailsReport'
));
const OrderRiderStatusReport = lazy(() => import(
  /* webpackChunkName:"OrderRiderStatusReport" */ '../module/report/page/orderRiderStatusReport/OrderRiderStatusReport'
));

const OLODetailsBatch = lazy(() => import(
  /* webpackChunkName:"BatchProductExclusion" */ '../module/oloManagement/batch/oLODetailsBatch/OLODetailsBatch'
));

const OrderDistanceReport = lazy(() => import(
  /* webpackChunkName:"OrderDistanceReport" */ '../module/report/page/orderDistanceReport/OrderDistanceReport'
));
const GiftCardSalesDetails = lazy(() => import(
  /* webpackChunkName:"orderDetailsReport" */ '../module/report/page/giftCardSalesReport/GiftCardSalesDetails'
));
const AuditTrial = lazy(() => import(
  /* webpackChunkName:"AuditTrial" */ '../module/report/page/audittrail/AudittrailReport'
));

const ValidationFailedReport = lazy(() => import(
  /* webpackChunkName:"AuditTrial" */ '../module/report/page/validationFailedOrders/ValidationFailedOrders'
));

const AggregatorAuditReport = lazy(() => import(
  /* webpackChunkName:"AuditTrial" */ '../module/report/page/aggregatorAuditReport/AggregatorAuditReport'
));
const StoreReport = lazy(() => import(
  /* webpackChunkName:"AuditTrial" */ '../module/report/page/storeReport/StoreReport'
));
const CollectionPoints = lazy(() => import(
  /* webpackChunkName:"AuditTrial" */ '../module/restaurantManagment/pages/collectioPoints/CollectionPoints'
));

const RestaurantCollectionPointsBatch = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/restaurantManagment/batch/restaurantCollectionPointsBatch/RestaurantCollectionPointsBatch'
));

const ScheduledExportReport = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/report/page/scheduledExportReport/ScheduledExportReport'
));

const StoreShutdownExportReport = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/report/page/storeShutdownExportReport/StoreShutdownExportReport'
));

const CustomerSearchMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/customerManagement/page/customerSearch/CustomerSearchMaster'
));

const CustomerOrderHistoryMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/customerManagement/page/customerOrderHistory/CustomerOrderHistoryMaster'
));

const PromotionApplicationHistoryMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/customerManagement/page/promotionApplicationHistory/PromotionApplicationHistoryMaster'
));

const PromotionRedemptionHistoryMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/customerManagement/page/promotionRedemptionHistory/PromotionRedemptionHistoryMaster'
));

const CompanySettingsMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/configManagement/page/companySettings/CompanySettingsMaster'
));
const ItemGroupMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/productManagement/pages/itemGroup/ItemGroupMaster'
));

const ProductExclusionAdvance = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/oloManagement/pages/productExclusionAdvance/ProductExclusionAdvance'
));

const ProductExclusionLookupAdvance = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/oloManagement/pages/productExclusionLookupAdvance/ProductExclusionLookupAdvance'
));

const OutOfStockMaster = lazy(() => import(
  /* webpackChunkName:"BatchRestaurantCollectionPoints" */ '../module/oloManagement/pages/outOfStock/OutOfStockMaster'
));

const OutOfStockBatch = lazy(() => import(
  /* webpackChunkName:"Batch" */ '../module/oloManagement/batch/outOfStockBatch/OutOfStockBatch'
));
const OutOfStockLookup = lazy(() => import(
  /* webpackChunkName:"Batch" */ '../module/oloManagement/pages/outOfStockLookup/OutOfStockLookup'
));
const Dragontail = lazy(() => import(
  /* webpackChunkName:"Doordash" */ '../module/restaurantManagment/pages/dragontail/Dragontail'
));
const ProductDayParting = lazy(() => import(
  /* webpackChunkName:"ProductDayParting" */ '../module/productManagement/pages/productDayParting/ProductDayParting'
));

// CBOHProductMaster
const CBOHProductMaster = lazy(() => import(
  /* webpackChunkName:"ProductDayParting" */ '../module/cboh/pages/cbohproductMaster/CBOHProductMaster'
));
const StockMaster = lazy(() => import(
  /* webpackChunkName:"StockMaster" */ '../module/restaurantManagment/pages/stockMaster/StockMaster'
));

const basepath = process.env.NODE_ENV !== 'development'
  ? process.env.PUBLIC_URL || ''
  : process.env.REACT_APP_SERVER_PATH;

export const publicRoutes = [
  {
    path: `${basepath}/signout`,
    component: SignOut,
    id: 25,
    title: 'KFC Japan',
    pageName: 'Sign Out',
  },
  {
    path: `${basepath}/signin`,
    component: SignIn,
    id: 26,
    title: 'KFC Japan',
    pageName: 'Sign In',
  },
];

export const privateRoutes = [
  {
    path: `${basepath}/`,
    exact: true,
    component: DashboardMaster,
    id: 1,
    title: 'KFC Japan',
    pageName: 'Dashboard',
    parent: null,
    private: true,
    uniqueKey: 'ExecutiveSummary',
  },
  {
    path: `${basepath}/RestaurantMaster`,
    exact: false,
    component: RestaurantMaster,
    id: 2,
    title: 'KFC Japan',
    pageName: 'Restaurant Master',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantMaster',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantChannelServiceMapping`,
    exact: false,
    component: RestaurantServiceMapping,
    id: 3,
    title: 'KFC Japan',
    pageName: 'Service Mapping',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantChannelServiceMapping',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantOperatingHours`,
    exact: false,
    component: RestaurantOperatingHours,
    id: 4,
    title: 'KFC Japan',
    pageName: 'Operating Hours',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantOperatingHours',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OLOAttributes`,
    exact: true,
    component: OLODetails,
    id: 5,
    title: 'KFC Japan',
    pageName: 'OLO Details',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'SetupOLOAttribute',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/LookupShutdown`,
    exact: true,
    component: RestaurantShutdownLookupMaster,
    id: 6,
    title: 'KFC Japan',
    pageName: 'Restaurant Shutdown Lookup ',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'LookupShutdown',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantPaymentMapping`,
    exact: true,
    component: RestaurantPaymentMapping,
    id: 7,
    title: 'KFC Japan',
    pageName: 'Restaurant Payment Mapping',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantPaymentMapping',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ScheduleShutdown`,
    exact: false,
    component: TechnicalShutdownMaster,
    id: 8,
    title: 'KFC Japan',
    pageName: 'Restaurant Shutdown',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ScheduleShutdown',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantPOSMapping`,
    exact: false,
    component: PosMappingMaster,
    id: 9,
    title: 'KFC Japan',
    pageName: 'Restaurant POS Mapping',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'POSMapping',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantChannelServiceMappingBatch`,
    component: RestaurantServiceMappingBatch,
    id: 10,
    title: 'KFC Japan',
    pageName: 'Service Mapping Batch',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantChannelServiceMapping',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/RestaurantPaymentMappingBatch`,
    component: RestaurantPaymentBatch,
    id: 11,
    title: 'KFC Japan',
    pageName: 'Payment Mapping Batch',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantPaymentMapping',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/RestaurantOperatingHoursBatch`,
    component: RestaurantOperatingHoursBatch,
    id: 12,
    title: 'KFC Japan',
    pageName: 'Operating Hours Batch',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantOperatingHours',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ShutdownBatch`,
    component: ScheduleShutdownBatch,
    id: 13,
    title: 'KFC Japan',
    pageName: 'Shutdown Batch',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ScheduleShutdown',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ProductExclusion`,
    component: ProductExclusion,
    id: 14,
    title: 'KFC Japan',
    pageName: 'Product Exclusion',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ProductExclusion',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/LookupHoliday`,
    component: HolidayLookupMaster,
    id: 15,
    title: 'KFC Japan',
    pageName: 'Holiday Lookup',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'LookupHoliday',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ProductExclusionBatch`,
    component: ProductExclusionBatch,
    id: 16,
    title: 'KFC Japan',
    pageName: 'Product Exclusion Batch',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ProductExclusion',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ProductExclusionLookup`,
    component: ProductExclusionLookup,
    id: 17,
    title: 'KFC Japan',
    pageName: 'Product Exclusion Lookup',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ProductExclusionLookup',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/Holiday`,
    component: Holiday,
    id: 18,
    title: 'KFC Japan',
    pageName: 'Holiday',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'Holiday',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/HolidayBatch`,
    component: HolidayBatch,
    id: 19,
    title: 'KFC Japan',
    pageName: 'Holidays Batch',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'Holiday',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ProductMaster`,
    component: ProductMaster,
    id: 20,
    title: 'KFC Japan',
    pageName: 'Product Master',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'ProductMaster',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RecommendedProduct`,
    component: ProductRecommendation,
    id: 21,
    title: 'KFC Japan',
    pageName: 'Product Recommendation',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'ProductRecommendation',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/UpsellCrossSellAddons`,
    component: UpsellCrossSellAddons,
    id: 22,
    title: 'KFC Japan',
    pageName: 'Upsell CrossSell Addons',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'UpsellCrossSellAddons',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/KIOSKConfiguration`,
    component: KioskConfigurationMaster,
    id: 23,
    title: 'KFC Japan',
    pageName: 'Kiosk Configuration',
    parent: 'Kiosk',
    private: true,
    uniqueKey: 'KIOSKConfiguration',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/TenantManagement`,
    component: TenantManagement,
    id: 24,
    title: 'KFC Japan',
    pageName: 'Tenant Management',
    parent: null,
    private: true,
    uniqueKey: 'TenantManagement',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/Netbanking`,
    component: NetBanking,
    id: 25,
    title: 'KFC Japan',
    pageName: 'Net Banking',
    parent: 'Payment Configurarion',
    private: true,
    uniqueKey: 'NetBanking',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/Otherwallet`,
    component: OtherWallet,
    id: 26,
    title: 'KFC Japan',
    pageName: 'Other Wallets',
    parent: 'Payment Configurarion',
    private: true,
    uniqueKey: 'OtherWallets',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/Billdesk`,
    component: BillDesk,
    id: 27,
    title: 'KFC Japan',
    pageName: 'Bill Desk',
    parent: 'Payment Configurarion',
    private: true,
    uniqueKey: 'BillDesk',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/Tenderoffer`,
    component: TenderOffer,
    id: 28,
    title: 'KFC Japan',
    pageName: 'Tender Offer',
    parent: 'Payment Configurarion',
    private: true,
    uniqueKey: 'TenderOffer',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ImageUpload`,
    component: ImageUploadMaster,
    id: 29,
    title: 'KFC Japan',
    pageName: 'Image Upload',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'ImageUpload',
  },
  {
    path: `${basepath}/Promotions`,
    component: PromotionMaster,
    id: 30,
    title: 'KFC Japan',
    pageName: 'Promotions',
    parent: 'Promotion & Offers',
    private: true,
    uniqueKey: 'Promotions',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/Coupon`,
    component: CouponMaster,
    id: 31,
    title: 'KFC Japan',
    pageName: 'Coupon',
    parent: 'Promotion & Offers',
    private: true,
    uniqueKey: 'Coupon',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/AggregatorOffers`,
    component: AggregatorOfferMaster,
    id: 32,
    title: 'KFC Japan',
    pageName: 'Aggregator Offers',
    parent: 'Promotion & Offers',
    private: true,
    uniqueKey: 'AggregatorOffers',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/CouponAssignment`,
    component: CouponAssignment,
    id: 33,
    title: 'KFC Japan',
    pageName: 'Coupon Assignment',
    parent: 'Promotion & Offers',
    private: true,
    uniqueKey: 'CouponAssignment',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/PublishRestaurant`,
    component: PublishRestaurant,
    id: 34,
    title: 'KFC Japan',
    pageName: 'Publish Restaurant',
    parent: 'Restaurant Management',
    private: true,
    uniqueKey: 'PublishRestaurant',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/PublishRestaurantBatch`,
    component: RestaurantPublishBatch,
    id: 35,
    title: 'KFC Japan',
    pageName: 'Publish Restaurant Batch',
    parent: 'Restaurant Management',
    private: true,
    uniqueKey: 'PublishRestaurant',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ManageRole`,
    component: ManageRole,
    id: 36,
    title: 'KFC Japan',
    pageName: 'Manage role',
    parent: 'User Management',
    private: true,
    uniqueKey: 'ManageRole',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OrderReport`,
    component: OrderReport,
    id: 37,
    title: 'KFC Japan',
    pageName: 'Order Report',
    parent: 'Reports',
    private: true,
    exact: false,
    uniqueKey: 'OrderReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/TimePeriodDetailReport`,
    component: TimePeriodDetailReport,
    id: 38,
    title: 'KFC Japan',
    pageName: 'Time Period Detail Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'TimePeriodReport',
  },
  {
    path: `${basepath}/CouponRedemptionReport`,
    component: CouponRedemptionReport,
    id: 39,
    title: 'KFC Japan',
    pageName: 'Coupon Redemption Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'CouponRedemptionReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/menupull`,
    component: MenuPull,
    id: 40,
    title: 'KFC Japan',
    pageName: 'Menu pull',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'MenuPull',
  },
  {
    path: `${basepath}/menupulllookup`,
    component: MenuPullLookUp,
    id: 41,
    title: 'KFC Japan',
    pageName: 'Menu pull look up',
    private: true,
    uniqueKey: 'MenuPullLookUp',
  },
  {
    path: `${basepath}/GiftCardUsageReport`,
    component: GiftCardUsageReport,
    id: 42,
    title: 'KFC Japan',
    pageName: 'Gift Card Usage Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'GiftCardUsageReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/GiftCardPurchaseReport`,
    component: GiftCardPurchaseReport,
    id: 43,
    title: 'KFC Japan',
    pageName: 'Gift Card Purchase Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'GiftCardPurchaseReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/CallCenterAgentReport`,
    component: CallCenterAgentReport,
    id: 44,
    title: 'KFC Japan',
    pageName: 'Gift Card Agent Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'CallCenterAgentReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OnlineStorePerformanceReport`,
    component: OnlineStorePerformanceReport,
    id: 45,
    title: 'KFC Japan',
    pageName: 'Online Store Performance Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'OnlineStorePerformanceReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/PaymentStatusReport`,
    component: PaymentStatusReport,
    id: 46,
    title: 'KFC Japan',
    pageName: 'Payment Status Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'PaymentStatusReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ProductExclusionReport`,
    component: ProductExclusionReport,
    id: 47,
    title: 'KFC Japan',
    pageName: 'Product Exclusion Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'ProductExclusionReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OrderReportDetails`,
    component: OrderReportFilterReport,
    id: 48,
    title: 'KFC Japan',
    pageName: 'Order Report Details',
    parent: 'Reports',
    private: true,
    uniqueKey: 'OrderReport',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OrderRiderStatusReport`,
    component: OrderRiderStatusReport,
    id: 49,
    title: 'KFC Japan',
    pageName: 'Order Rider Status Report',
    parent: 'Reports',
    private: true,
    uniqueKey: 'OrderRiderStatusReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OLOAttributesBatch`,
    component: OLODetailsBatch,
    id: 50,
    title: 'KFC Japan',
    pageName: 'OLODetailsBatch',
    private: true,
    parent: 'OLO Administration',
    uniqueKey: 'SetupOLOAttribute',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/OrderDistanceReport`,
    component: OrderDistanceReport,
    id: 51,
    title: 'KFC Japan',
    pageName: 'OrderDistanceReport',
    private: true,
    uniqueKey: 'OrderDistanceReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/giftcardsalesdetails`,
    component: GiftCardSalesDetails,
    id: 52,
    title: 'KFC Japan',
    pageName: 'GiftCardSalesDetails',
    private: true,
    uniqueKey: 'OrderDistanceReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/auditTrail`,
    component: AuditTrial,
    id: 53,
    title: 'KFC Japan',
    pageName: 'auditTrail',
    private: true,
    uniqueKey: 'AuditTrailReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ValidationFailedReport`,
    component: ValidationFailedReport,
    id: 54,
    title: 'KFC Japan',
    pageName: 'ValidationFailedReport',
    private: true,
    parent: 'Reports',
    uniqueKey: 'ValidationFailedReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantCollectionPoints`,
    component: CollectionPoints,
    id: 55,
    title: 'KFC Japan',
    pageName: 'Restaurant Collection Points',
    private: true,
    parent: 'Restaurent Management',
    uniqueKey: 'RestaurantCollectionPoints',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantCollectionPointsBatch`,
    component: RestaurantCollectionPointsBatch,
    id: 56,
    title: 'KFC Japan',
    pageName: 'Restaurant Collection Points',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantCollectionPoints',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/AggregatorAuditLogsReport`,
    component: AggregatorAuditReport,
    id: 57,
    title: 'KFC Japan',
    pageName: 'AggregatorAuditLogsReport',
    private: true,
    parent: 'Reports',
    uniqueKey: 'AggregatorAuditLogsReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ScheduledExportReport`,
    component: ScheduledExportReport,
    id: 58,
    title: 'KFC Japan',
    pageName: 'ScheduledExportReport',
    private: true,
    parent: 'Reports',
    uniqueKey: 'ScheduledExportReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/StoreReport`,
    component: StoreReport,
    id: 59,
    title: 'KFC Japan',
    pageName: 'StoreReport',
    private: true,
    parent: 'Reports',
    uniqueKey: 'StoreReport',
    // page name to send in error message log service
  }, {
    path: `${basepath}/StoreShutdownExportReport`,
    component: StoreShutdownExportReport,
    id: 60,
    title: 'KFC Japan',
    pageName: 'StoreShutdownExportReport',
    private: true,
    parent: 'Reports',
    uniqueKey: 'StoreShutdownExportReport',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/AdditionalCharges`,
    component: RestaurantAdditionalCharges,
    id: 61,
    title: 'KFC Japan',
    pageName: 'AdditionalCharges',
    private: true,
    parent: 'Restaurent Management',
    uniqueKey: 'AdditionalCharges',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantGroups`,
    exact: false,
    component: RestaurantGroups,
    id: 62,
    title: 'KFC Japan',
    pageName: 'Restaurant Groups',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantGroups',
  },
  {
    path: `${basepath}/CustomerSearch`,
    exact: false,
    component: CustomerSearchMaster,
    id: 63,
    title: 'KFC Japan',
    pageName: 'Customer Search',
    parent: 'Customer Management',
    private: true,
    uniqueKey: 'CustomerSearch',
  },
  {
    path: `${basepath}/CustomerOrderHistory`,
    exact: false,
    component: CustomerOrderHistoryMaster,
    id: 64,
    title: 'KFC Japan',
    pageName: 'Customer Order History',
    parent: 'Customer Management',
    private: true,
    uniqueKey: 'CustomerOrderHistory',
  },
  {
    path: `${basepath}/CompanySettings`,
    exact: false,
    component: CompanySettingsMaster,
    id: 65,
    title: 'KFC Japan',
    pageName: 'Compant Settings',
    parent: 'Config Management',
    private: true,
    uniqueKey: 'CompanySettings',
  },
  {
    path: `${basepath}/RestaurantDeliveryHoursBatch`,
    component: RestaurantDeliveryHoursBatch,
    id: 66,
    title: 'KFC Japan',
    pageName: 'Trading Hours Batch',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantOperatingHours',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/RestaurantTradingHoursBatch`,
    component: RestaurantTradingHoursBatch,
    id: 67,
    title: 'KFC Japan',
    pageName: 'Trading Hours Batch',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantOperatingHours',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/PromotionApplicationHistory`,
    component: PromotionApplicationHistoryMaster,
    id: 68,
    title: 'KFC Japan',
    pageName: 'Promotion Application History',
    parent: 'Customer Management',
    private: true,
    uniqueKey: 'CustomerSearch',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/PromotionRedemptionHistory`,
    component: PromotionRedemptionHistoryMaster,
    id: 69,
    title: 'KFC Japan',
    pageName: 'Promotion Redemption History',
    parent: 'Customer Management',
    private: true,
    uniqueKey: 'CustomerSearch',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/ItemGroup`,
    exact: false,
    component: ItemGroupMaster,
    id: 70,
    title: 'KFC Japan',
    pageName: 'Item Groups',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'ItemGroups',
  },
  {
    path: `${basepath}/ProductExclusionAdvance`,
    exact: false,
    component: ProductExclusionAdvance,
    id: 71,
    title: 'KFC Japan',
    pageName: 'Product Exclusion Advance',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ProductExclusion',
  },
  {
    path: `${basepath}/ProductExclusionLookupAdvance`,
    exact: false,
    component: ProductExclusionLookupAdvance,
    id: 72,
    title: 'KFC Japan',
    pageName: 'Product Exclusion Lookup Advance',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'ProductExclusionLookup',
  },
  {
    path: `${basepath}/outOfStock`,
    exact: false,
    component: OutOfStockMaster,
    id: 73,
    title: 'KFC Japan',
    pageName: 'Product Out Of Stock',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'OutOfStock',
  },
  {
    path: `${basepath}/outOfStockBatch`,
    component: OutOfStockBatch,
    id: 74,
    title: 'KFC Japan',
    pageName: 'Out of Stock Batch',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'OutOfStock',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/outOfStockLookup`,
    component: OutOfStockLookup,
    id: 75,
    title: 'KFC Japan',
    pageName: 'Out of Stock Look Up',
    parent: 'OLO Administration',
    private: true,
    uniqueKey: 'OutOfStockLookup',
    isLinkedRoute: true,
    // page name to send in error message log service
  },
  {
    path: `${basepath}/RestaurantSchedulingHoursBatch`,
    component: RestaurantSchedulingHoursBatch,
    id: 76,
    title: 'KFC Japan',
    pageName: 'Scheduling Hours Batch',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantOperatingHours',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/DeliveryPartnerMapping`,
    component: Dragontail,
    id: 77,
    title: 'KFC Japan',
    pageName: 'Delivery Partner Mapping',
    parent: 'Restaurant Management',
    private: true,
    uniqueKey: 'DeliveryPartnerMapping',
    isLinkedRoute: true,
  },
  {
    path: `${basepath}/RestaurantPointPaymentMapping`,
    exact: true,
    component: RestaurantPointPaymentMapping,
    id: 78,
    title: 'KFC Japan',
    pageName: 'Restaurant Points Payment Mapping',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'RestaurantPointPaymentMapping',
  },
  {
    path: `${basepath}/ProductDayParting`,
    component: ProductDayParting,
    id: 79,
    title: 'KFC Japan',
    pageName: 'Product Day Parting',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'ProductDayParting',
    // page name to send in error message log service
  },
  {
    path: `${basepath}/ProductDetails`,
    component: ProductDetails,
    id: 81,
    title: 'KFC Japan',
    pageName: 'Product Master',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'ProductDetails',
  },
  {
    path: `${basepath}/cbohproductmaster`,
    component: CBOHProductMaster,
    id: 82,
    title: 'KFC Japan',
    pageName: 'CBOH Product Master',
    parent: 'Product Management',
    private: true,
    uniqueKey: 'CBOHProductMaster',
  },
  {
    path: `${basepath}/StockMaster`,
    exact: true,
    component: StockMaster,
    id: 84,
    title: 'KFC Japan',
    pageName: 'Stock Master',
    parent: 'Restaurent Management',
    private: true,
    uniqueKey: 'StockMaster',
  },
  {
    path: '*',
    component: PageNotFound,
    id: 80,
    title: 'KFC Japan',
    pageName: 'Page Not Found',
    parent: null,
    private: true,
    uniqueKey: '*',
  },
];
